.grey {
  color: grey;
}

.black {
  color: black;
}

.tag {
  padding-right: 10px;
}

.list-item {
  margin: 10px;
}
.cross-button {
  padding: 2px;
  display: inline !important;
}

.title-text {
  display: inline;
}

.tag-label {
  text-transform: capitalize;
}

.skeleton-stack {
  max-width: 100%;
}
